import * as React from "react"

export interface UsePaginationProps {
  contentPerPage: number
  count: number
}

export interface UsePaginationReturn {
  page: number
  totalPages: number
  nextPage: () => void
  setPage: (page: number) => void
}

type UsePagination = (UsePaginationProps) => UsePaginationReturn

const usePagination: UsePagination = ({
  contentPerPage,
  count,
  currentPage = 1,
}) => {
  const [page, setPage] = React.useState(currentPage)
  // number of pages in total (total items / content on each page)
  const pageCount = Math.ceil(count / contentPerPage)

  React.useEffect(() => {
    if (currentPage === page || currentPage > pageCount) {
      return
    }
    setPage(currentPage)
  }, [currentPage])

  const changePage = () => {
    setPage((state) => {
      // if page is the last page, do nothing
      if (state === pageCount) {
        return state
      }
      return state + 1
    })
  }

  const setPageSAFE = (num: number) => {
    // if number is greater than number of pages, set to last page
    if (num > pageCount) {
      setPage(pageCount)
      // if number is less than 1, set page to first page
    } else if (num < 1) {
      setPage(1)
    } else {
      setPage(num)
    }
  }

  return {
    totalPages: pageCount,
    nextPage: () => changePage(),
    setPage: setPageSAFE,
    page,
  }
}

export default usePagination
